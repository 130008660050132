import React, { Component } from "react";
import { Button, DatePicker, Table, Select } from "antd";
import { Formik } from "formik";
import moment from "moment";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { alertError } from "../../utils/alert";
import { cardBuyReportAPI } from "../../Graphs/Report/userCardBuyReport";
import { getCityListAPI } from "../../Graphs/Master/City/getCityListAPI";
import { formatePrice } from "../../utils/general";

const { RangePicker } = DatePicker;

class CardBuyReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      cityData: [],
      startDate: null,
      endDate: null,
      isLoading: false,
      isTableOpen: false,
      adminTotalAmount:0,
      subAdminTotalAmount: 0,
      companyTotalAmount: 0,
      mintApiTotalAmount: 0,
      likeApiTotalAmount: 0,
    };
  }

  resetExpensesReportTable = () => {
    this.setState({
      data: [],
      isTableOpen: false,
      adminTotalAmount:0,
      subAdminTotalAmount: 0,
      companyTotalAmount: 0,
      mintApiTotalAmount: 0,
      likeApiTotalAmount: 0,
    });
  };

  onChangeDate = (date) => {
    if (!date || !date.length) {
      this.resetExpensesReportTable();
      return;
    }
    this.setState({
      startDate: date[0]?.format("YYYY-MM-DD"),
      endDate: date[1]?.format("YYYY-MM-DD"),
    });
  };

  fetchCityList = async () => {
    try {
      const { authToken } = this.props;

      this.setState({ isLoading: true });
      const response = await getCityListAPI(authToken);

      if (response.status === 200) {
        const cityOptions = response.data.map((city) => ({
          label: city.name,
          value: city.id,
        }));

        cityOptions.unshift({ label: "All", value: "" });

        this.setState({ cityData: cityOptions });
      } else {
        throw response.message || "Error fetching city data.";
      }
    } catch (error) {
      alertError("CITY LIST", error);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  componentDidMount() {
    this.fetchCityList();
  }

  netCardBuyReportList = async (startDate, endDate, city) => {
    try {
      const { authToken } = this.props;

      // If city is "All" (empty string), pass "" in the API payload
      const payload = { startDate, endDate, city };

      this.setState({ isLoading: true });
      const response = await cardBuyReportAPI(authToken, payload);
     
      if (response.status === 200) {
        response.data.forEach((element) => {
          this.setState({
            adminTotalAmount: this.state.adminTotalAmount + element?.admin_total_amount ?? 0,
            subAdminTotalAmount:
              this.state.subAdminTotalAmount + element?.sub_admin_total_amount ?? 0,
              companyTotalAmount:
              this.state.companyTotalAmount + element?.company_total_amount ?? 0,
            mintApiTotalAmount:
              this.state.mintApiTotalAmount + element?.mint_api_total_amount ?? 0,
            likeApiTotalAmount:this.state.likeApiTotalAmount + element?.like_api_total_amount ?? 0,
            
          });
        });
        this.setState({
          data: response.data,
          isTableOpen: true,
        });
      } else {
        throw response.message || "Error fetching report data.";
      }
    } catch (error) {
      alertError("REPORT", error);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  columns = [
    {
      title: "Sr. No.",
      key: "serial_number",
      width: 100,
      render: (text, item, index) => <span>{index + 1}</span>,
    },
    {
      title: "Card Name",
      key: "card_name",
      width: 200,
      render: (text, item) => item.card?.name || "-",
    },
    {
      title: "City Name",
      key: "city_name",
      width: 200,
      render: (text, item) => item.user_city?.name || "-",
    },
    {
      title: "Sub-Admin Count",
      key: "sub_admin_count",
      width: 200,
      render: (text, item) => item.sub_admin_count || 0,
    },
    {
      title: "Sub-Admin Total Amount",
      key: "sub_admin_count",
      width: 200,
      render: (text, item) => item.sub_admin_total_amount || 0,
    },
    {
      title: "Admin Count",
      key: "admin_count",
      width: 200,
      render: (text, item) => item.admin_count || 0,
    },
    {
      title: "Admin Total Amount",
      key: "admin_total_amount",
      width: 200,
      render: (text, item) => item.admin_total_amount || 0,
    },
    {
      title: "Company Count",
      key: "company_count",
      width: 200,
      render: (text, item) => item.company_count || 0,
    },
    {
      title: "Company Total Amount",
      key: "company_total_amount",
      width: 200,
      render: (text, item) => item.company_total_amount || 0,
    },
    {
      title: "Mint API Count",
      key: "mint_api_count",
      width: 200,
      render: (text, item) => item.mint_api_count || "0",
    },
    {
      title: "Mint Api Total Amount",
      key: "mint_api_total_amount",
      width: 200,
      render: (text, item) => item.mint_api_total_amount || "0",
    },
    
    {
      title: "Like API Count",
      key: "like_api_count",
      width: 200,
      render: (text, item) => item.like_api_count || "0",
    },
    {
      title: "Like Api Total Amount",
      key: "like_api_total_amount",
      width: 200,
      render: (text, item) => item.like_api_total_amount || "0",
    },
  ];
  
  render() {
    const { isTableOpen, data, isLoading, startDate } = this.state;

    return (
      <>
      <div className="sub-admin_card_buy_report">
        <Formik
          initialValues={{ date: "" }}
          onSubmit={() => {}}
        >
          {({ handleSubmit }) => (
            <div style={{ display: "flex", gap: "10px", margin: "20px 0" }}>
              <RangePicker
                format="YYYY-MM-DD"
                onChange={this.onChangeDate}
                disabledDate={(current) => current && current > moment().endOf("day")}
              />
            </div>
          )}
        </Formik>
        <Formik
          initialValues={{
            city: "",
          }}
          onSubmit={(values) => {
            this.resetExpensesReportTable();
            const { startDate, endDate } = this.state;
            const { city } = values;

            if (!startDate || !endDate) {
              return alertError("REPORT", "Please select a valid date range.");
            }

            this.netCardBuyReportList(startDate, endDate, city);
          }}
        >
          {({ handleSubmit, setFieldValue, values }) => (
            <div
              style={{
                marginTop: "20px",
                display: "flex",
                gap: "10px",
                marginBottom: "20px",
                justifyContent: "left",
              }}
            >
              <Select
                style={{ width: "200px" }}
                placeholder="Select City"
                value={values.city}
                onChange={(value) => setFieldValue("city", value)}
                options={this.state.cityData}
              />

              <Button
                type="primary"
                onClick={handleSubmit}
                disabled={!this.state.startDate}
              >
                Filter
              </Button>
            </div>
          )}
        </Formik>
      </div>

        {/* Table */}
        {isTableOpen && (
          <Table
            dataSource={data}
            columns={this.columns}
            loading={isLoading}
            rowKey={(data) => data.card_id}
            pagination={false}
            scroll={{ x: 2500 }}
            summary={(pageData) => {
              return (
                <>
                  <Table.Summary.Row style={{ fontWeight: 700, position: "sticky", bottom: "0", zIndex: 1, background: "white" }}>
                    <Table.Summary.Cell style={{ fontWeight: 700, fixed: true}}>
                      Total
                    </Table.Summary.Cell>
                    <Table.Summary.Cell style={{ fontWeight: 700 }}>
                      -
                    </Table.Summary.Cell>
                    <Table.Summary.Cell style={{ fontWeight: 700 }}>
                      -
                    </Table.Summary.Cell>
                    <Table.Summary.Cell style={{ fontWeight: 700 }}>
                      -
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      {formatePrice(this.state.subAdminTotalAmount)}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell style={{ fontWeight: 700 }}>
                      -
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                    {formatePrice(this.state.adminTotalAmount)}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell style={{ fontWeight: 700 }}>
                      -
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      {formatePrice(this.state.companyTotalAmount)}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell style={{ fontWeight: 700 }}>
                      -
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      {formatePrice(this.state.mintApiTotalAmount)}
                    </Table.Summary.Cell>
            
                    <Table.Summary.Cell style={{ fontWeight: 700 }}>
                      -
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      {formatePrice(this.state.likeApiTotalAmount)}
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
});

export default connect(mapStateToProps)(withRouter(CardBuyReport));
