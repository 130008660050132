export const errorMessages = {
  password_required: "Password is required",
  password_validation:
    "Password should at least 8 length and have special character",
  otp_validation: "Please enter a valid OTP",
  otp_required: "Please enter the OTP",
  mobile_validation: "Please enter a valid phone number",
  mobile_length_validation: "Phone number should less than 12 digits",
  mobile_required: "Phone number is required",
  email_validation: "Please enter valid email",
  email_required: "Email is required",
  first_name_required: "First name is required",
  fname_capital: "First letter must be capital",
  lname_capital: "First letter must be capital",
  last_name_required: "Last name is required",
  arabic_name_required: "Arabic name is required",
  price_validation: "Please enter a valid amount.",
  price_required: "Please enter amount.",
  space_validation: "Space is not allowed.",
  card_required: "Please enter card ",
  amount_required: "Please enter amount",
  commission_require: "Please enter the percentage",
  thirdPartyCategory_required: "Please select category",
  thirdPartySubCategory_required: "Please select sub category",
  innerSubCategory_required: "Please select inner category",
};
