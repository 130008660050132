import {notification, Popconfirm, Spin, Tooltip } from "antd";
import * as React from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { User } from "react-feather";
import TableWrap from "../common/Table";
import { alertError } from "../../utils/alert";
import Title from "../common/Title";
import { getWalletRequestsData } from "../../Graphs/walletRequest/getWalletRequestsData";
import { walletActivate } from "../../Graphs/walletRequest/walletActivate";
import { SET_PROFIT_REQUESTS_DATA, SET_PROFIT_REQUESTS_TOTAL } from "../../constant/actionTypes";

class ProfitWalletRequests extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      cardLimit: {},
      isLoading: true,
      startIndex: 0,
      limitNumber: 10,
      pagination: {
        pageSize: 10,
        current: 1,
      },
    };

    this.handleTableChange = this.handleTableChange.bind(this);
    this.getLimitedWalletRequest = this.getLimitedWalletRequest.bind(this);
  }

  async componentDidMount() {
    const { setRouteName } = this.props;
    setRouteName("PROFIT-WALLET-REQUESTS");
    this.profitRequestsData(0, 10);
    this.setState({
      data: this.props.data,
      isLoading: false,
      cardSelectedData: null,
      cardLimit: null,
    });
  
  }

  async getLimitedWalletRequest(pagination) {
    const { search } = this.props;
    const start =
      pagination.current * pagination.pageSize - pagination.pageSize;
    const end = pagination.pageSize;

    this.setState(
      {
        isLoading: true,
        startIndex: start,
        limitNumber: end,
      },
      async () => {
        if (search != null && search != "") {
          this.handleWalletRequestListSearch(start, end);
        } else {
          this.profitRequestsData(start, end);
        }
      }
    );
  }

  handleWalletRequestListSearch = async (start, end) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        setProfitWalletRequestsData,
        setProfitWalletRequestsTotal,
        search,
        loginUserData
      } = this.props;
      this.setState({
        isLoading: true,
      });

    
      const city = loginUserData.role === "Sub_admin" ? loginUserData.city : "";
       const response = await getWalletRequestsData(authToken, start, end, city, search);
       
      if (response.status == 200) {
        //set user Data
        await setProfitWalletRequestsData(response.data);
        await setProfitWalletRequestsTotal(response.total);

        this.setState({
          isLoading: false,
          data: response.data,
        });
      } else if (response.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw response && response.message ? response.message : "Error";
      }
    } catch (e) {
      alertError("Inventory Batch List", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  profitRequestsData = async (start, end) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        setProfitWalletRequestsData,
        setProfitWalletRequestsTotal,
        loginUserData
      } = this.props;
      this.setState({
        isLoading: true,
      });
      const city = loginUserData.role === "Sub_admin" ? loginUserData.city : "";
       const response = await getWalletRequestsData(authToken, start, end,city, "");
      if (response.status == 200) {
        await setProfitWalletRequestsData(response.data);
        await setProfitWalletRequestsTotal(response.total);

        this.setState({
          isLoading: false,
          data: response.data,
        });
      } else if (response.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
         history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw response && response.message ? response.message : "Error";
      }
    } catch (e) {
      alertError("Inventory Batch List", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };
  handleTableChange(pagination, filters) {
    this.setState(
      {
        pagination,
      },
      () => this.getLimitedWalletRequest(pagination)
    );
  }
  handleConfirmActive = (item) => {
    this.walletActivateRequest(item);
  };
  
  walletActivateRequest = async (item) => {
   const {startIndex,limitNumber} = this.state
    try {
      const { authToken } = this.props;
      const body = {
        user_id: item.id.toString(),
      };
      
      this.setState({
        isLoading: true,
      });
    
      const response = await walletActivate(body,authToken);

      if (response.status == 200) {
        notification["success"]({
          message: "Profit Wallet Request",
          description: response.message,
        });
        this.profitRequestsData(startIndex, limitNumber);

      } else {
        throw response && response.message ? response.message : "Error";
      }
    } catch (e) {
      alertError("Profit Wallet Request", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }
  counter = 0;
  tempCounter = 0;
   columns = [
    {
      title: "Sr. No.",
      key: "serial_number",
      fixed: true,
      render: (text, item, index) => (
        <div>
          <span>{index + 1}</span>
        </div>
      ),
    },
    {
      title: "Name",
      key: "Name",
      fixed: true,
      render: (text, item) => (
        <div>
          <span>{item.first_name + " " + item.last_name || "-"}</span>
          </div>
      ),
    },
    {
      title: "Email",
      key: "Email",
      fixed: true,
      render: (text, item) => (
        <div>
          <strong>{item.email || "-"}</strong>
        </div>
      ),
    },
    {
      title: "Mobile",
      key: "note",
      render: (text, item) => (
        <div>
          <span>{item.phone || "-"}</span>
        </div>
      ),
    },
    {
      title: "Shop Name",
      key: "shop_name",
      render: (text, item) => (
        <div>
          <span>{item.shop_name || 0}</span>
        </div>
      ),
    },
    
    {
      title: "Actions",
      key: "action",
      render: (text, item, index) => {
      return(
        <div style={{ display: "flex", flexWrap: "wrap" }}>
        <Tooltip placement="bottom" title="Activate">
          <Popconfirm
            onConfirm={this.handleConfirmActive.bind(this, item)}
            title="Are you sure you want to activate wallet?"
          >
            <button className="btn btn-primary btn-sm" type="button">Activate</button>
          </Popconfirm>
        </Tooltip>
      </div>
      
      )
      }
     
    },
  ];
  
  render() {
    const {
      isLoading,
    } = this.state;
    const { profitRequestsData, profitRequestsTotal, searchLoader} =
      this.props;
    return isLoading || searchLoader ? (
      <div style={{ marginLeft: "20px" }}>
        <Spin
          size="large"
          style={{ marginLeft: "480px", marginTop: "130px" }}
        />
      </div>
    ) : (
      <div style={{ "overflow-x": "auto" }}>
        <Title
          parent="Profit Wallet Requests"
          title="Profit Wallet Requests"
          icon={<User />}
          url="/profit-wallet-requests"
        />
        <TableWrap
          data={profitRequestsData}
          columns={this.columns}
          isLoading={this.state.isLoading}
          total={profitRequestsTotal}
          pagination={this.state.pagination}
          handleTableChange={this.handleTableChange}
          scroll={{ x: 1440 }}
        />
     
 
      
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  profitRequestsData: state.walletRequests.profitRequestsData,
  profitRequestsTotal: state.walletRequests.profitRequestsData,
  searchLoader: state.auth.searchLoader,
  search: state.auth.searchText,
  loginUserData: state.auth.loginUserData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_USER_AUTHTOKEN",
        authToken: authToken,
      });
    },
    setProfitWalletRequestsData: (data) => {
      dispatch({
        type: SET_PROFIT_REQUESTS_DATA,
        profitRequestsData: data,
      });
    },
    setProfitWalletRequestsTotal: (data) => {
      dispatch({
        type: SET_PROFIT_REQUESTS_TOTAL,
        profitRequestsTotal: data,
      });
      
    },
    setRouteName: (routeName) => {
      dispatch({
        type: "SET_ROUTE_NAME",
        routeName: routeName,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ProfitWalletRequests));